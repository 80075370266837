<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
import Swal from "sweetalert2";

export default {
    page: {
        title: "Buat Surat Tugas Validasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Rapat Banding",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },    
                {
                    text: "Rapat Banding",
                    active: "/pasca-asesmen/daftar-banding-asesor",	
                },
                {
                    text: "Detail Rapat Banding",
                    active: true,
                },
                
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],

            //modal
            showModalSimpan: false,
            hasil_rapat : `Rapat hari ini menyepakati untuk menyetujui pengajuan banding yang diajukan oleh Asesi atas penolakan pemberian sertifikat. Pengajuan banding dilakukan berdasarkan keputusan yang tidak memenuhi syarat oleh tim penilaian sebelumnya. Melalui presentasi yang meyakinkan, Asesi berhasil menggambarkan bukti kompetensi dan prestasi yang relevan untuk memperoleh sertifikat.

                    Para peserta rapat sepakat bahwa pengajuan banding ini dilakukan dengan itikad baik dan berdasarkan kebenaran. Dalam upaya mendukung keadilan dan transparansi, kami menilai ulang seluruh portofolio Asesi dan mengakui pencapaian signifikan yang telah mereka raih.

                    Oleh karena itu, dengan suara bulat, rapat ini menyetujui untuk memberikan sertifikat kepada Asesi sebagai pengakuan resmi atas kompetensi dan kualifikasi mereka. Keputusan ini diambil dengan keyakinan bahwa pemberian sertifikat akan mendorong perkembangan profesional Asesi dan memberikan manfaat positif bagi karir mereka.

                    Kami mengucapkan selamat kepada Asesi atas prestasi mereka yang luar biasa. Diharapkan sertifikat ini dapat meningkatkan kesempatan kerja dan pengakuan dalam industri terkait. Semoga ini menjadi langkah awal menuju kesuksesan yang lebih besar di masa depan.

                    Terima kasih atas perhatian dan partisipasi semua pihak dalam rapat ini. Semoga keputusan yang diambil hari ini berdampak positif bagi perkembangan individu dan kemajuan industri secara keseluruhan.

                    Sekian hasil rapat ini disampaikan untuk menjadi panduan dan catatan resmi bagi semua pihak terkait.`,

            meta_dokumen: [
                {
                    'nama_dokumen': 'FR.VA Memberikan Kontribusi Dalam Validasi Asesmen'
                },
                {
                    'nama_dokumen': 'FR.IA.11 Ceklis Meninjau Instrumen Asesmen'
                },
                {
                    'nama_dokumen': 'Ceklis Konsultasi Pra Asesmen'
                },
                {
                    'nama_dokumen': 'Ceklis Verifikasi TUK'
                },
                {
                    'nama_dokumen': 'FR.APL-01 Permohonan Sertifikasi Kompetensi + Bukti Persyaratan Dasar'
                },
                {
                    'nama_dokumen': 'FR.APL.02 Asesmen Mandiri + Portofolio Peserta'
                },
                {
                    'nama_dokumen': 'FR.MAPA.01 Merencanakan Aktivitas dan Proses Asesmen'
                },
                {
                    'nama_dokumen': 'FR.MAPA.02 Peta Instrumen Asesmen Hasil Pendekatan Asesmen dan Perencanaan Kerja'
                },
                {
                    'nama_dokumen': 'SKEMA Sertifikasi + Standar Kompetensi Kerja'
                },
                {
                    'nama_dokumen': 'FR.AK.04 Banding Asesmen'
                },
                
            ],
            }
    },
    mounted() {
    },
    methods: {
        addDokumen() {
            this.meta_dokumen.push({
                nama_dokumen: "",
            });
        },
        removeDokumen(index) {
            this.meta_dokumen.splice(index, 1);
        },
        modalSimpan() {
            let self = this;
            self.showModalSimpan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSimpan = true;
            });
        },
        StoreData() {
            let self = this;
            Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Tunggu Sebentar",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                // self.showModalSimpan = false;
                self.$router.push({ name: "detail-daftar_muk_validasi", params: { id: 1 } });
                }
            });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' ||
                                    axiosCatchError instanceof String
                                    ">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>Detail Rapat Banding</h3>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Tanggal Surat Tugas</label>
                                                <div class="col">
                                                    <input id="" class="form-control" type="date" for="text" value="2023-07-21">
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Nomor Tugas</label>
                                                <div class="col">
                                                    <input id="" class="form-control" type="text" for="text" value="0058/LSP/MUK/II/2023">
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Asesor Banding</label>
                                                <div class="col">
                                                    <input id="" class="form-control" type="text" for="text" value="Muhammad Gumi Adrean">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            
                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Tanggal Rapat Banding</label>
                                                <div class="col">
                                                    <input id="" class="form-control" type="date" for="text" value="2023-07-30">
                                                </div>
                                            </div>
                                            <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                                <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Tempat Pelaksana</label>
                                                <div class="col">
                                                    <input id="" class="form-control" type="text" for="text" value="Gedung LSP MUK">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 mt-4">
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>No Asesmen</th>
                                                    <th>Nomer Surat Banding</th>
                                                    <th>Skema Sertifikasi</th>
                                                    <th>Asesi</th>
                                                    <th>Asesor Kompetensi</th>
                                                    <th>Asesor Banding</th>
                                                    <th>Status</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                <tr>
                                                    <td>1</td>
                                                    <td>00078</td>
                                                    <td>0058/LSP/MUK/II/2023</td>
                                                    <td>Pelaksana Patologi dan Toksikologi</td>
                                                    <td>Muhammad Rizki</td>
                                                    <td>Muhammad ilham sagita Putra</td>
                                                    <td>Joko saputro</td>
                                                    <td><span class="badge bg-danger">Ditolak</span></td>
                                                    <td class="text-center">
                                                        <div class="d-grid gap-1">
                                                            <router-link :to="{ name: 'detail-banding-asesor', params: { id: 1 } }"
                                                                class="btn btn-sm btn-primary bg-primary"><i
                                                                    class="fas fa-search"></i> Detail</router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>00080</td>
                                                    <td>0080/LSP/MUK/II/2023</td>
                                                    <td>Pelaksana Patologi dan Toksikologi</td>
                                                    <td>Muhammad Surya Pratama</td>
                                                    <td>Ade Surya</td>
                                                    <td>Joko saputro</td>
                                                    <td><span class="badge bg-warning">Proses banding</span></td>
                                                    <td class="text-center">
                                                        <div class="d-grid gap-1">
                                                            <router-link :to="{ name: 'detail-banding-asesor', params: { id: 2 } }"
                                                                class="btn btn-sm btn-primary bg-primary"><i
                                                                    class="fas fa-search"></i> Detail</router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                  
                                    <!-- <div class="form-group col-md-12 mt-4">
                                        <label for="tipe_menu">Daftar Dokumen </label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Dokumen</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="meta_dokumen.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in meta_dokumen" :key="index">
                                                    <td>
                                                        {{index + 1}}
                                                    </td>
                                                    <td>
                                                        {{item.nama_dokumen }}
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-primary"><i
                                                                class="fas fa-search"></i></button>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> -->
                                    <div class="row mt-4 text-center">
                                        <h3>Preview hasil rapat</h3>
                                        <div class="row p-4">
                                             <textarea class="form-control" id="exampleFormControlTextarea1" rows="10" v-model="hasil_rapat"></textarea>
..
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row d-flex flex-wrap mb-2" id="example text" role="group">
                                        <label class="col-lg-4 col-form-label col-form-label" id="" for="text">Hasil rapat</label>
                                        <div class="col">
                                            <input id="" class="form-control" type="text" for="text" value="Diterima">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end">
                                        <b-button type="submit" variant="primary" class="m-1"><i
                                                class="fa fa-save"></i>
                                            Simpan</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <!-- <h4 class="card-title mb-5">Timeline</h4> -->
                                <div class="hori-timeline" dir="ltr">
                                    <div class="scroll-timeline">
                                        <ul class="list-inline events">
                                            <li class="list-inline-item event-list">
                                                <div class="px-4">
                                                    <div class="event-date bg-soft-primary text-primary">30 Juli 2023</div>
                                                    <h6 class="font-size-14">Buat Surat Tugas</h6>
                                                    <p class="text-muted">Admin LSP</p>
                                                    <div></div>
                                                </div>
                                            </li>
                                            <li class="list-inline-item event-list">
                                                <div class="px-4">
                                                    <div class="event-date bg-soft-primary text-primary">30 Juli 2023</div>
                                                    <h6 class="font-size-14">Proses Asesmen</h6>
                                                    <p class="text-muted">Asesor</p>
                                                    <div></div>
                                                </div>
                                            </li>
                                            <li class="list-inline-item event-list">
                                                <div class="px-4">
                                                    <div class="event-date bg-soft-success text-success">31 Juli 2023</div>
                                                    <h6 class="font-size-14">Selesai</h6>
                                                    <p class="text-muted">Asesor</p>
                                                    <div></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    
</template>
<style scoped>
.col-md-custom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
}

.list-inline{
    margin-top: 10px;
}

.hori-timeline .events {
    border-top: 3px solid #e9ecef;
}

.hori-timeline .events .event-list {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 70px;
    margin-right: 0;
}

.hori-timeline .events .event-list:before {
    content: "";
    position: absolute;
    height: 36px;
    border-right: 2px dashed #dee2e6;
    top: 0;
}

.hori-timeline .events .event-list .event-date {
    position: absolute;
    top: 38px;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    border-radius: 4px;
    padding: 2px 4px;
}

@media (min-width: 1140px) {
    .hori-timeline .events .event-list {
        display: inline-block;
        width: 24%;
        padding-top: 45px;
    }

    .hori-timeline .events .event-list .event-date {
        top: -12px;
    }
}

.bg-soft-primary {
    background-color: rgba(64, 144, 203, .3) !important;
}

.bg-soft-success {
    background-color: rgba(71, 189, 154, .3) !important;
}

.bg-soft-danger {
    background-color: rgba(231, 76, 94, .3) !important;
}

.bg-soft-warning {
    background-color: rgba(249, 213, 112, .3) !important;
}

.card {
    border: none;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
    box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
}
.scroll-timeline{
    overflow: auto;
    white-space: nowrap;
}
</style>

